function toggleTextareaBorder() {
    const checkbox = document.getElementById('is_technical');
    const textarea = document.getElementById('adnotacja');

    if (checkbox.checked) {
        textarea.style.border = '2px dashed #ebeef1';

        let textElement = document.getElementById('technical-note-text');
        if (!textElement) {
            textElement = document.createElement('p');
            textElement.id = 'technical-note-text';
            textElement.style.color = '#606770'; 
            textElement.style.fontSize = '14px';
            textElement.style.marginTop = '8px';
            textElement.innerHTML = '<b>Uwaga:</b> Tryb notatki technicznej!';

            textarea.parentNode.insertBefore(textElement, textarea.nextSibling);
        }
    } else {
        textarea.style.border = '';

        const textElement = document.getElementById('technical-note-text');
        if (textElement) {
            textElement.remove();
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const checkbox = document.getElementById('is_technical');
    if (checkbox) {
        console.log("click");
        checkbox.addEventListener('change', toggleTextareaBorder);
    }
});